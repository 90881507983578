
import {
  SET_DOCUMENT,
  CREATE_DOCUMENT,
  DOCUMENTS_RECEIVED,
  SET_PROPERTY_DOCUMENT,
  SET_LOADING_OUTPUTS,
  SET_DOCUMENT_BUFFER,
  USER_AVATAR_DOCUMENT_OUTPUT,
  SET_CAMPAIGN_DOCUMENT,
  SET_DOCUMENT_BLOCKS
} from "../types/documents";

const schema = {

}

const documentsReducer = (state, { type, payload }) => {
  switch (type) {
    case DOCUMENTS_RECEIVED:
      return { ...state, documents: payload };
    case SET_DOCUMENT:
      return { ...state, document: payload };
    case SET_DOCUMENT_BLOCKS:
      return { ...state, documentBlocks: payload };
    case SET_CAMPAIGN_DOCUMENT:
      return { ...state, campaginDocument: payload };
    case SET_DOCUMENT_BUFFER:
      return { ...state, documentBuffer: payload };
    case SET_LOADING_OUTPUTS:
      return { ...state, loadingOutputs: payload };
    case CREATE_DOCUMENT:
      return { ...state, document: schema };
    case USER_AVATAR_DOCUMENT_OUTPUT:
      return { ...state, userDocumentAvatarOutput: payload };
    case SET_PROPERTY_DOCUMENT: {
      const { key, value } = payload;
      const document = { ...state.document };
      document[key] = value;
      return { ...state, document };
    }
    default:
      return { ...state };
  }
};

export default documentsReducer;
