import React, { createContext, useReducer, useContext } from "react";
import DocumentsService from "../services/DocumentsService";
import DocumentsReducer from "../reducers/DocumentsReducer";
import {
  DOCUMENTS_RECEIVED,
  SET_DOCUMENT,
  CREATE_DOCUMENT,
  SET_PROPERTY_DOCUMENT,
  SET_LOADING_OUTPUTS,
  SET_DOCUMENT_BUFFER,
  USER_AVATAR_DOCUMENT_OUTPUT,
  SET_CAMPAIGN_DOCUMENT,
  SET_DOCUMENT_BLOCKS,
} from "../types/documents";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import useLocalStorageOrganization from "../hooks/organizations/useLocalStorageOrganization";

const defaultBlocks = [
  {
    id: "sheNwCUP5A",
    type: "header",
    data: {
      text: "Start writing here!",
      level: 2
    }
  },
];

const initialState = {
  documents: null,
  document: null,
  campaginDocument: null,
  loadingOutputs: false,
  documentBuffer: null,
  userDocumentAvatarOutput: null,
  documentBlocks: []
};

export const DocumentsContext = createContext(initialState);

export const DocumentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DocumentsReducer, initialState);
  const { alert, success, clearModal } = useContext(ModalContext);
  const { getStoredOrganization } = useLocalStorageOrganization();

  const getDocuments = (filters = {}) => {
    const storedOrganization = getStoredOrganization();
    let organization_id = "";
    if (storedOrganization && storedOrganization.organization_id) {
      organization_id = storedOrganization.organization_id;
    }
    DocumentsService.getDocuments(organization_id, filters)
      .then((response) => {
        const { documents } = response.data;
        dispatch({ type: DOCUMENTS_RECEIVED, payload: documents });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleDocument = (document_id, campaignDocument) => {
    DocumentsService.getSingleDocument(document_id)
      .then((response) => {
        const { document } = response.data;
        if (campaignDocument) {
          setCampaignDocument(document);
        } else {
          dispatch({ type: SET_DOCUMENT, payload: document });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getDocumentByCampaign = (campaing_document_id, campaing_id) => {
    DocumentsService.getDocumentByCampaign(campaing_document_id, campaing_id)
      .then((response) => {
        const { document } = response.data;
        dispatch({ type: SET_DOCUMENT, payload: document });
      })
      .catch((error) => {
        alert(error);
      });
  }

  const getDocumentConverted = (document_id) => {
    DocumentsService.getDocumentConverted(document_id)
      .then((response) => {
        setDocumentBuffer(response.data);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setDocument = (document) => {
    dispatch({ type: SET_DOCUMENT, payload: document });
  };

  const setDocumentBlocks = (blocks) => {
    dispatch({ type: SET_DOCUMENT_BLOCKS, payload: blocks });
  };

  const setDefaultDocumentBlocks = () => {
    setDocumentBlocks(defaultBlocks);
  }

  const setCampaignDocument = (payload) => {
    dispatch({ type: SET_CAMPAIGN_DOCUMENT, payload });
  };

  const setDocumentBuffer = (buffer) => {
    dispatch({ type: SET_DOCUMENT_BUFFER, payload: buffer });
  };

  const setLoadingOutputs = (payload) => {
    dispatch({ type: SET_LOADING_OUTPUTS, payload });
  };

  const createDocument = () => {
    dispatch({ type: CREATE_DOCUMENT });
  };

  const setPropertyDocument = (key, value) => {
    dispatch({ type: SET_PROPERTY_DOCUMENT, payload: { key, value } });
  };

  const setAvatarDocumentOutput = (payload) => {
    dispatch({ type: USER_AVATAR_DOCUMENT_OUTPUT, payload });
  }

  const saveDocument = (document, callback) => {
    const storedOrganization = getStoredOrganization();
    if (storedOrganization) {
      document.organization_id = storedOrganization.organization_id;
    }

    let service = DocumentsService.putDocument;
    if (isNaN(parseInt(document.document_id))) {
      service = DocumentsService.postDocument;
    }

    service(document)
      .then(() => {
        success("Document saved.");
        getDocuments();
        clearModal()

        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const documentUserAvatarOutput = (data, callback) => {
    dispatch({ type: SHOW_SPINNER });

    DocumentsService.generateAvatarOutput(data).then((response) => {

      if (typeof callback === "function") {
        callback();
      }
    }).catch((error) => {
      alert(error);
    });


  };

  const deleteDocument = (document_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    DocumentsService.deleteDocument(document_id)
      .then(() => {
        getDocuments();
        success("Document deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <DocumentsContext.Provider
      value={{
        ...state,
        setDocument,
        setLoadingOutputs,
        setAvatarDocumentOutput,
        getDocuments,
        saveDocument,
        deleteDocument,
        createDocument,
        getSingleDocument,
        setPropertyDocument,
        getDocumentConverted,
        getDocumentByCampaign,
        documentUserAvatarOutput,
        setDocumentBlocks,
        setDefaultDocumentBlocks,
        setCampaignDocument
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};
