import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const TemplateInfo = () => {
  const { clearModal } = useContext(ModalContext);
  return (
    <div>
      <h4>What Are Templates?</h4>
      <p className="small">
        Templates are pre-made prompts with particular blanks to be filled by
        you.
      </p>
      <h4>How Do They Work?</h4>
      <p className="small">
        When you create a prompt you like using Fetch, copy and paste it in the
        content input field and replace dynamic fields with {"[field]"}.
      </p>
      <p>
        Then, you can use the template to create more advanced content with your
        Avatar.
      </p>
      <h4>Can You Give Me An Example?</h4>
      <p>
        I'm looking for a Facebook ad copy that will use the social proof and
        credibility of [influencer] to persuade my [customer] to try my
        [product] and share their positive experience with their followers.
      </p>
      <p>
        When you use this template, you will be ask to write a "influencer", a
        "customer", and a "product".
      </p>
      <p className="bold mb-2">Example Result</p>
      <p>
        I'm looking for a Facebook ad copy that will use the social proof and
        credibility of
        <span className="text-accent bold mx-1">Jeffery Boyle</span>to persuade
        my
        <span className="text-accent bold mx-1">entrepreneur audience</span>
        to try my
        <span className="text-accent bold mx-1">
          BemodoAI Content Wizard
        </span>{" "}
        and share their positive experience with their followers.
      </p>
      <button onClick={clearModal} className="btn btn-primary w-100">
        OK
      </button>
    </div>
  );
};

export default TemplateInfo;
