import React, { useContext, useEffect, useState } from "react";
import { AvatarsContext } from "../../context/AvatarsContext";
import { ModalContext } from "../../context/ModalContext";
import AvatarDeleteFile from "./AvatarDeleteFile";

const AvatarFileCard = ({ 
  fileName,
  fileId,
  type,
  openaiFileId,
  assistantId,
 }) => {
  const [name, setName] = useState('');
  const { avatar, deleteAvatarFile } = useContext(AvatarsContext);
  const { modalComponent, confirm } = useContext(ModalContext);

  useEffect(() => {
    handleFileNameLength();
  }, []);

  const handleFileNameLength = () => {
    if(fileName.length > 15) {
      const updatedName = `${fileName.substring(0, 15)}..`;
      setName(updatedName);
    } else {
      setName(fileName);
    }
  }

  const handleDeleteFile = () => {
    const deleteData = {
      fileId,
      openaiFileId,
      assistantId
    }

    modalComponent(
      'Are you sure to delete this file?',
      <AvatarDeleteFile 
        fileName={fileName}
        fileType={type}
        deleteData={deleteData}
      />
    )
  }

  return (
    <div className="card w-100 bg-light mb-2">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3 className="mb-0">{name}.{type}</h3>
        <i onClick={handleDeleteFile} className="fas fa-trash fs-4 text-primary cursor-pointer"/>
      </div>
    </div>
  );
};

export default AvatarFileCard;
