import React, { createContext, useContext, useReducer } from "react";
import SocketReducer from "../reducers/SocketReducer";
import { ModalContext } from "./ModalContext";
import { hideModal } from "../utils";
import { SET_SOCKET } from "../types/socket";

const initialState = {
    socket: null,
};

export const SocketContext = createContext(initialState);

export const SocketProvider = ({ children }) => {
    const [state, dispatch] = useReducer(SocketReducer, initialState);

    const { success } = useContext(ModalContext);

    const setSocket = (payload) => {
      dispatch({type: SET_SOCKET, payload});
    }


    return (
        <SocketContext.Provider
          value={{
            ...state,
            setSocket
          }}
        >
          {children}
        </SocketContext.Provider>
    );
};
