import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import SingleConversation from "./SingleConversation";
import { Link } from "@reach/router";
import Upgrade from "./Upgrade";
import { ConversationsContext } from "../context/ConversationsContext";

const Walkthrough = () => {
  const [conversationId, setConversationId] = useState(null);
  const { conversations } = useContext(ConversationsContext);
  const { user, signOut } = useContext(AuthContext);

  useEffect(() => {
    if (user !== null && Array.isArray(conversations)) {
      const conversation = conversations[0];
      if (conversation) setConversationId(conversation.conversation_id);
    }
  }, [user, conversations]);

  const renderConversation = () => {
    if (conversationId !== null) {
      return <SingleConversation conversation_id={conversationId} />;
    }
    return (
      <Upgrade>
        <p>
          Creating more content with{" "}
          <span className="text-accent">
            Bemodo's AI Content Assitant "Fetch"
          </span>{" "}
          requires an active subscription to BemodoAI.
        </p>
      </Upgrade>
    );
  };

  return (
    <div id="walkthrough" className="container-fluid">
      {user.has_access && (
        <div id="walkthrough-video" className="card p-3 shadow">
          <iframe
            title="Bemodo AI Walkthrough"
            src="https://player.vimeo.com/video/853213336?h=1c8a0e72ab&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div className="row mt-3">
            <div className="col-6">
              <Link to="/pricing" className="btn btn-primary w-100">
                Upgrade
              </Link>
            </div>
            <div className="col-6">
              <button onClick={signOut} className="btn text-muted w-100">
                Log out
              </button>
            </div>
          </div>
        </div>
      )}

      {renderConversation()}
    </div>
  );
};

export default Walkthrough;
