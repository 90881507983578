import api from "./api";
import { getArgs } from "../utils";

const route = "/avatars";

const AvatarsService = {
  getMyAvatars: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getPublicAvatars: () => api.get(`${route}/public/all`),
  getSingleAvatar: (avatar_id) => api.get(`${route}/${avatar_id}`),
  getSuperFetchAvatar: () => api.get(`${route}/super-fetch`),
  postAvatar: (avatar) => api.post(route, { ...avatar }),
  putAvatar: (avatar) => api.put(route, { ...avatar }),
  addAvatarContext: (context, avatar_id, name) => {
    return api.put(`${route}/add-context/${avatar_id}`, { context, name })
  },
  postAvatarFile: (formData, assistant_id, avatar_id) => {
    return api.post(
    `${route}/add-file/${assistant_id}/${avatar_id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  sendAvatarMessage: (user_id, assistant_id, message) => {
    return api.post(`${route}/user-message/${user_id}/${assistant_id}`, {
      userMessage: message
    });
  },
  deleteAvatarFile: (data) => api.delete(
    `${route}/file/${data.fileId}/${data.openaiFileId}/${data.assistantId}`),
  deleteAvatar: (avatar_id) => api.delete(`${route}/${avatar_id}`),
  refineAvatar: (avatar_id) => api.post(`${route}/refine`, { avatar_id }),
  combineAvatar: (
    selected_avatar_id,
    target_avatar_id,
    target_training_id,
    name
  ) =>
    api.post(`${route}/combine`, {
      selected_avatar_id,
      target_avatar_id,
      target_training_id,
      name,
    }),
};
export default AvatarsService;
